export const environment = {
  appVersion: 'Dev V ' + require('../../package.json').version,
  // domainApi: 'ad-sa-tech.siamesedev.com',
  // apiUrl: 'https://ad-sa-tech.siamesedev.com',
  domainApi: 'localhost:4022',
  apiUrl: 'http://localhost:4022',
  adminApi: '/AdminService',
  masterApi: '/MasterService',
  partnerApi: '/PartnerService',
  ticketApi: '/TicketService',
  authApi: '/AuthService',
  customerApi: '/CustomerService',
  uploadApi: '/UploadService',
  technicianApi: '/PartnerService',
  plantsApi: '/PlantService',
  activityApi:'/ActivityService',
  quotationApi:'/QuotationService',
  invoiceApi:'/InvoiceService',
  surveyApi:'/SurveyService',
  receiptApi:'/ReceiptService',
  requestInHouseService:'/RequestInHouseService',
}; 